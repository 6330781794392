import React from "react";

function Navbar() {
    return (
        <div id="navbar">
            <h3 id="navHeader">Start Bootstrap</h3>
            <ul id="navList">
                <li>HOME</li>
                <li>ABOUT</li>
                <li>SAMPLE POST</li>
                <li>CONTACT</li>
            </ul>
        </div>
    )
}

export default Navbar;